import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { useState } from "react"
import ReactPlayer from "react-player"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import SiteMetadata from "../components/SiteMetadata"

import Layout from "../layouts/Layout"

import "./ShowItem.scss"

export const RichTextSection = ({ text }) => {
  if (!text) {
    return <></>
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b>{text}</b>,
      [MARKS.ITALIC]: text => <i>{text}</i>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children) => (
        <h1 className="text-3xl mb-3">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <h2 className="text-2xl mb-3">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <h3 className="text-xl mb-3 font-semibold">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (_, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (_, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (_, children) => <h6>{children}</h6>,
      [BLOCKS.PARAGRAPH]: (_, children) => <p className="mb-5">{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const image = node.data.target.fields.file["en-US"].url
        const title = node.data.target.fields.title["en-US"]
        return <img src={image} alt={title} />
      },
    },
  }

  if (text.json) {
    const output = documentToReactComponents(text.json, options)
    return <div className="rich-text">{output}</div>
  }

  return (
    <div
      className="rich-text-field"
      dangerouslySetInnerHTML={{ __html: text?.childContentfulRichText?.html }}
    />
  )
}

const CastAndCreatives = ({ castAndCreatives }) => {
  return (
    <div>
      <div className="flex flex-wrap">
        {castAndCreatives
          .filter(({ headshot }) => headshot?.localFile?.childImageSharp?.fluid)
          .map(({ credit, name, headshot }) => (
            <Img
              key={`${credit}-${name}`}
              className="m-2 w-24 md:flex-grow-0 flex-grow"
              fluid={headshot.localFile.childImageSharp.fluid}
              alt={name}
            />
          ))}
      </div>
      {castAndCreatives.map(({ credit, name }) => {
        return (
          <div key={`${credit}-${name}`} className="flex my-3">
            <div className="overflow-hidden md:whitespace-no-wrap">
              {credit}
              {new Array(500).fill(".")}
            </div>
            <div className="whitespace-no-wrap text-right flex items-end">
              <span>{name}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

function timeDisplay(secs) {
  const minutes = Math.floor(secs / 60)
  const seconds = Math.round(secs % 60)
  const prettySeconds = seconds < 10 ? `0${seconds}` : seconds
  return `${minutes}:${prettySeconds}`
}

const AudioAlbum = ({ album }) => {
  const [currentTrack, setCurrentTrack] = useState()
  const [progress, setProgress] = useState()
  const [duration, setDuration] = useState()
  const [paused, setPaused] = useState(false)

  const toggleTrack = track => {
    if (track === currentTrack) {
      setPaused(!paused)
    } else {
      setCurrentTrack(track)
      setProgress(0)
      setPaused(false)
    }
  }

  const handleProgress = progress => {
    setProgress(Math.round(progress.playedSeconds))
  }

  const handleDuration = duration => {
    setDuration(duration)
  }

  const playNextTrack = () => {
    const nextTrackIndex =
      album.audioFiles.findIndex(
        ({ localFile }) => localFile.publicURL === currentTrack
      ) + 1

    if (album.audioFiles[nextTrackIndex]) {
      toggleTrack(album.audioFiles[nextTrackIndex].localFile.publicURL)
    } else {
      toggleTrack(undefined)
    }
  }

  return (
    <div>
      <h3 className="text-xl mb-2">{album.title}</h3>
      <RichTextSection text={album.description} />
      <ReactPlayer
        height={0}
        width={0}
        playing={!paused}
        url={currentTrack}
        onProgress={handleProgress}
        onDuration={handleDuration}
        progress={progress}
        paused={paused}
        onEnded={playNextTrack}
      />
      <ul>
        {album.audioFiles.map(({ title, localFile }) => (
          <li key={title}>
            <button
              onClick={() => {
                toggleTrack(localFile.publicURL)
              }}
              className="my-1 p-2 bg-gray-200 hover:bg-gray-400 min-w-full text-left flex justify-between"
            >
              {title}{" "}
              <span>
                {localFile.publicURL === currentTrack &&
                  duration > 0 &&
                  `${timeDisplay(progress)}/${timeDisplay(duration)} `}
                {localFile.publicURL === currentTrack && !paused ? (
                  <>&#9646;&#9646;</>
                ) : (
                  <>&#9658;</>
                )}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

const SectionHeader = ({ children }) => (
  <h2 className="text-3xl mt-10 mb-5">{children}</h2>
)

export default props => {
  const {
    showName,
    showLogo,
    castAndCreatives,
    about,
    book,
    synopsis,
    audioAlbums,
  } = props.data.item

  return (
    <Layout>
      <SiteMetadata
        title={showName}
        description={`Learn about ${showName}, a show by Tony Scialli`}
      />
      <div className="max-w-screen-md mx-auto px-5">
        {showLogo?.localFile?.childImageSharp?.fluid && (
          <Img
            style={{ maxWidth: 500 }}
            className="mx-auto"
            fluid={showLogo.localFile.childImageSharp.fluid}
            alt={showName}
          />
        )}
        <SectionHeader>About {showName}</SectionHeader>
        <RichTextSection text={about} />
        <SectionHeader>Synopsis</SectionHeader>
        <RichTextSection text={synopsis} />
        {book?.localFile?.publicURL && (
          <>
            <SectionHeader>Book</SectionHeader>
            <p className="mb-5">
              Click the following link to download the full {showName} book:
            </p>
            <a
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              href={book.localFile.publicURL}
            >
              Download the full {showName} book
            </a>
          </>
        )}
        {castAndCreatives?.length && (
          <>
            <SectionHeader>Cast and Creatives</SectionHeader>
            <CastAndCreatives castAndCreatives={castAndCreatives} />
          </>
        )}
        {audioAlbums?.length && (
          <>
            <SectionHeader>Media</SectionHeader>
            {audioAlbums.map(album => (
              <AudioAlbum key={album.title} album={album} />
            ))}
          </>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ShowItemQuery($slug: String!) {
    item: contentfulShow(slug: { eq: $slug }) {
      showName
      slug
      showLogo {
        localFile {
          childImageSharp {
            fluid(maxHeight: 444, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      about {
        childContentfulRichText {
          html
        }
        json
      }
      book {
        localFile {
          publicURL
        }
      }
      synopsis {
        childContentfulRichText {
          html
        }
        json
      }
      castAndCreatives {
        credit
        name
        headshot {
          localFile {
            childImageSharp {
              fluid(maxHeight: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      audioAlbums {
        audioFiles {
          localFile {
            publicURL
          }
          title
        }
        title
        description {
          childContentfulRichText {
            html
          }
          json
        }
      }
    }
  }
`
